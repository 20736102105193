import config from "../../utils/config";
import { toast } from "react-toastify";
import { CardContent, Card, Typography, Button, Avatar } from "@mui/material";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";

function EventPurchaseDetails(props) {
  const { purchaseId } = useParams();

  const [purchase, setPurchase] = useState({});
  const [student, setStudent] = useState({});
  const [studentOrganization, setStudentOrganization] = useState(null);
  const [paidTransaction, setPaidTransaction] = useState(null);
  const [showConfirmPayPopup, setShowConfirmPayPopup] = useState(false);

  useEffect(() => {
    props.setTitle("Event Purchase Details");
  }, []); // eslint-disable-line

  useEffect(() => {
    fetchPurchase();
  }, [purchaseId]); // eslint-disable-line

  useEffect(() => {
    if (student.organization != null && student.organization !== purchase.organization) {
      fetchStudentOrganization();
    }
  }, [student, purchase]); // eslint-disable-line

  useEffect(() => {
    if (purchase.paid) {
      fetchPaidTransaction(purchase._id);
    }

    if (purchase.esnCard != null && purchase.esnCard !== "") {
      fetchStudentFromESNcard(purchase.esnCard);
    }
  }, [purchase]); // eslint-disable-line

  const fetchPaidTransaction = (purchaseId) => {
    axios
      .get(
        `${config.API_ENDPOINT}/transactions/${props.organization}/search?relationId=${purchaseId}`,
        { headers: { "x-access-token": localStorage.getItem("access-token") } }
      )
      .then((res) => setPaidTransaction(res.data))
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const fetchPurchase = () => {
    axios
      .get(`${config.API_ENDPOINT}/event-purchases/${props.organization}/${purchaseId}`, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => setPurchase(res.data))
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const fetchStudentFromESNcard = (card) => {
    axios
      .get(`${config.API_ENDPOINT}/students/esncard/${card}`)
      .then((res) => setStudent(res.data))
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const fetchStudentOrganization = () => {
    axios
      .get(`${config.API_ENDPOINT}/organizations/${student.organization}`)
      .then((res) => setStudentOrganization(res.data))
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const pay = useDebouncedCallback(
    () => {
      if (purchase.event.seatsRemaining != null && purchase.event.seatsRemaining <= 0) {
        setShowConfirmPayPopup(true);
      } else {
        sendPay();
      }
    },
    500,
    { leading: true }
  );

  const sendPay = () => {
    setShowConfirmPayPopup(false);
    axios
      .post(
        `${config.API_ENDPOINT}/event-purchases/${props.organization}/${purchaseId}/pay`,
        {},
        { headers: { "x-access-token": localStorage.getItem("access-token") } }
      )
      .then(() => {
        toast.success("Success!");
        fetchPurchase();
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const performCheckin = useDebouncedCallback(
    () => {
      axios
        .post(
          `${config.API_ENDPOINT}/event-purchases/${props.organization}/${purchaseId}/checkin`,
          {},
          { headers: { "x-access-token": localStorage.getItem("access-token") } }
        )
        .then(() => {
          toast.success("Success!");
          fetchPurchase();
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const cancelCheckin = useDebouncedCallback(
    () => {
      axios
        .post(
          `${config.API_ENDPOINT}/event-purchases/${props.organization}/${purchaseId}/cancel-checkin`,
          {},
          { headers: { "x-access-token": localStorage.getItem("access-token") } }
        )
        .then(() => {
          toast.success("Success!");
          fetchPurchase();
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const getOptionValue = (value, type) => {
    switch (type) {
      case "select-multi":
        return value.join(", ");
      case "datetime":
        return moment(value).format("DD-MM-YYYY HH:mm");
      default:
        return value;
    }
  };

  let eventName =
    purchase.event == null
      ? ""
      : purchase.shared
      ? purchase.event.parentEvent.name
      : purchase.event.name;
  let eventOptions =
    purchase.event == null
      ? {}
      : purchase.shared
      ? purchase.event.parentEvent.options
      : purchase.event.options;

  return (
    <div>
      <Card style={{ padding: 15 }}>
        <CardContent>
          <Typography style={{ fontWeight: "bold", fontSize: 40 }}>
            {purchase.event != null ? `Purchase of ${eventName}` : ""}
          </Typography>
          <Typography style={{ fontSize: 20, marginBottom: 50 }}>
            {moment(purchase.datetime).format("DD/MM/YYYY HH:mm")}
          </Typography>
          <Typography mb={3}>
            <strong>Purchased at:</strong> {purchase.organization?.name}
          </Typography>
          <Typography>
            <strong>Name:</strong> {purchase.name}
          </Typography>
          <Typography>
            <strong>Email:</strong> {purchase.email}
          </Typography>
          {purchase.esnCard ? (
            <Typography>
              <strong>ESNcard:</strong>{" "}
              {student.organization !== purchase.organization?._id ? (
                <span>{purchase.esnCard}</span>
              ) : (
                <Link style={{ color: "black" }} to={`/edit-student/${student._id}`}>
                  {purchase.esnCard}
                </Link>
              )}
            </Typography>
          ) : null}
          <Typography>
            <strong>Student Organization:</strong>{" "}
            {studentOrganization == null ? purchase.organization?.name : studentOrganization.name}
          </Typography>
          <Typography>
            <strong>ID/Passport:</strong> {purchase.passport}
          </Typography>
          <Typography>
            <strong>Phone Number:</strong> {purchase.phone}
          </Typography>
          <Typography>
            <strong>Birthdate:</strong> {moment(purchase.birthdate).format("DD/MM/YYYY")}
          </Typography>
          <Typography style={{ marginBottom: 20 }}>
            <strong>Country:</strong> {purchase.country}
          </Typography>
          <Typography style={{ marginBottom: 20 }}>
            <strong>Price:</strong> {purchase.price?.toFixed(2) + " €"}
          </Typography>
          {purchase.options != null
            ? Object.entries(purchase.options).map((entry, index) => (
                <Typography key={index}>
                  <strong>{entry[0]}:</strong> {getOptionValue(entry[1], eventOptions[index]?.type)}
                </Typography>
              ))
            : null}
          {purchase.checkedInAt != null ? (
            <Typography sx={{ mt: 3 }}>
              <strong>Checked-in at: </strong>{" "}
              {moment(purchase.checkedInAt).format("DD/MM/YYYY HH:mm")}
            </Typography>
          ) : null}
          {purchase.checkedInBy != null ? (
            <div>
              <strong>Checked-in by:</strong>
              <div
                style={{
                  marginTop: 5,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={
                    purchase.checkedInBy.avatarURL != null
                      ? `${config.IMG_ENDPOINT}/uploads/${purchase.checkedInBy.avatarURL}`
                      : null
                  }
                  style={{ marginRight: 10 }}
                />
                <p>
                  {purchase.checkedInBy.name} ({purchase.checkedInBy.email})
                </p>
              </div>
            </div>
          ) : null}

          {purchase.checkedInAt != null ? (
            <Button variant="contained" sx={{ mt: 3, display: "block" }} onClick={cancelCheckin}>
              Cancel Check-in
            </Button>
          ) : (
            <Button variant="contained" sx={{ mt: 3, display: "block" }} onClick={performCheckin}>
              Manual Check-in
            </Button>
          )}

          {paidTransaction && purchase.organization._id === props.organization ? (
            <Link
              to={`/transaction-details/${paidTransaction._id}`}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" sx={{ mt: 1 }}>
                View Transaction
              </Button>
            </Link>
          ) : !purchase.paid ? (
            <Button variant="contained" sx={{ mt: 1 }} onClick={pay}>
              Set as Paid
            </Button>
          ) : null}
          <br />
          {purchase.event == null ? null : (
            <Link
              to={
                purchase.shared
                  ? purchase.organization._id === props.organization
                    ? `/edit-shared-event/${purchase.event._id}`
                    : `/edit-event/${purchase.event.parentEvent._id}`
                  : `/edit-event/${purchase.event._id}`
              }
              style={{ textDecoration: "none" }}
            >
              <Button sx={{ mt: 1 }} variant="contained">
                View Event
              </Button>
            </Link>
          )}
        </CardContent>
      </Card>

      <ConfirmationDialog
        open={showConfirmPayPopup}
        message="This event is full. Do you want to proceed anyway?"
        onYes={sendPay}
        onClose={() => setShowConfirmPayPopup(false)}
      />
    </div>
  );
}

export default EventPurchaseDetails;
